import { useState, useEffect } from "react";
import {
  Stack,
  Card,
  CardContent,
  Typography,
  Divider,
  Button,
  IconButton,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getBatches, deleteBatch } from "../../redux/actions/batches";
import ServerPaginationGridNoRowCount from "../../components/Table/DataGrid";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import AlertDialog from "../../components/Dialog";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { formatDate } from "../../utils/dateUtils";

export const BatchesJD = ({ getBatches, batchesList, jDID }) => {
  const [paginationModals, setPaginationModals] = useState({
    page: 0,
    pageSize: 5,
  });
  const [tableHeight, setTableHeight] = useState(400);
  const rowHeight = 52;
  const updateTableHeight = (pageSize) => {
    const headerHeight = 56; // Approximate header height (adjust based on your actual header height)
    const footerHeight = 56; // Approximate footer height (adjust based on your actual footer height)
    const newHeight = headerHeight + footerHeight + rowHeight * pageSize;
    setTableHeight(newHeight);
  };

   const [open, setOpen] = useState(false);
   const [title, setTitle] = useState("");
   const [content, setContent] = useState("");
   const [confirm, setConfirm] = useState(false);
  const [btId, setBtId] = useState(null);
   const submitData = async (id, name) => {
     setBtId(id);
     setTitle("Delete Batch");
     setContent(`Are you sure you want to delete this batch :  ${name}?`);
     setOpen(true);
   };

   useEffect(() => {
     if (confirm && btId) {
       deleteBatch(
         btId,
         jDID,
         paginationModals.page,
         paginationModals.pageSize
       );
       setConfirm(false);
     }
   }, [confirm]);

  const columns = [
    {
      field: "bt_name",
      headerName: "Batch Name",
      flex: 1,
      minWidth: 330,
      renderCell: (params) => (
        <Link to={`/hr/batches/batch-detail/${params.row.bt_id}`}>
          {params.row.bt_name}
        </Link>
      ),
    },
    {
      field: "applicants",
      headerName: "CVs",
      flex: 1,
      // renderCell: (params) => (
      //   <>
      //     {params.row.applicants > 0 ? (
      //       <Link to={`/hr/candidates?batchId=${params.row.bt_id}`}>
      //         {params.row.applicants}
      //       </Link>
      //     ) : (
      //       params.row.applicants
      //     )}
      //   </>
      // ),
    },
    {
      field: "applicantsInvited",
      headerName: "Invite Sent",
      flex: 1,
    },
    {
      field: "applicantsCompleted",
      headerName: "Completed",
      flex: 1,
    },
    {
      field: "applicantsInProgress",
      headerName: "In Progress",
      flex: 1,
    },
    {
      field: "bt_model_name",
      headerName: "Analysis Done By",
      flex: 1,
    },
    {
      field: "bt_created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => (
        <>
          {params && params.row && params.row.bt_created_at
            ? formatDate(JSON.parse(localStorage.getItem("user")), params.row.bt_created_at)
            : ""}
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.applicants > 0 ? (
            ""
          ) : (
            <IconButton
              onClick={() => submitData(params.row.bt_id, params.row.bt_name)}
            >
              <DeleteForeverIcon sx={{ color: "red" }} />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    getBatches(jDID, paginationModals.page, paginationModals.pageSize);
  }, [jDID, paginationModals]);

  useEffect(() => {
    updateTableHeight(batchesList?.rows?.length);
  }, [paginationModals.pageSize, batchesList]);
  return (
    <>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        title={title}
        setConfirm={setConfirm}
        content={content}
      />
      <Card
        raised
        sx={{
          minWidth: "100%",
        }}
      >
        <CardContent>
          <Stack
            direction={{ xs: "row", sm: "row" }}
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5">Batches</Typography>
            <Typography
              sx={{ fontSize: 12 }}
              gutterBottom
              textAlign="right"
              variant="h4"
            >
              {jDID && jDID ? (
                <Button
                  component={Link}
                  to={`/hr/batches/create-batch/${jDID}`}
                  variant="text"
                  color="primary"
                  size={"small"}
                >
                  <AddIcon /> Create
                </Button>
              ) : null}
            </Typography>
          </Stack>
          <Divider />
          <div style={{ height: tableHeight, width: "100%" }}>
            <ServerPaginationGridNoRowCount
              setPaginationModals={setPaginationModals}
              paginationModals={paginationModals}
              rowsData={batchesList && batchesList.rows ? batchesList.rows : []}
              total={batchesList && batchesList.count ? batchesList.count : 0}
              columns={columns}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  batchesList: state.batches.jDBatchesList,
});

const mapDispatchToProps = { getBatches };

export default connect(mapStateToProps, mapDispatchToProps)(BatchesJD);
