import { useEffect, useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import AIInterview from "./AIInterview";
import { getApplicant } from "../../redux/actions/applicant";
import { getInterviewStatus } from "../../redux/actions/candidate";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import useFullScreen from "../../hooks/fullScreenWindow";
import { Token } from "@mui/icons-material";

const CandidateDashboard = ({
  getApplicant,
  applicant,
  skills,
  getInterviewStatus,
  auth: { user },
  interviewStatus,
}) => {
  const [mcqCompleted, setMcqCompleted] = useState(false);
  const [codingCompleted, setCodingCompleted] = useState(false);
  const { isFullScreen, enterFullScreen, exitFullScreen } = useFullScreen();

  const navigate = useNavigate(); // Initialize useNavigate
  

  // Error handling for fetching applicant and interview status
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user && user.u_apct_id_fk) {
          await getInterviewStatus(user.u_apct_id_fk);
          await getApplicant(user.u_apct_id_fk);
        }
      } catch (error) {
        console.error("Error fetching interview status or applicant data:", error);
      }
    };

    fetchData();
  }, [user, getInterviewStatus, getApplicant]);

  // Set the state of MCQ and Coding test completion based on applicant data
  useEffect(() => {
    if (applicant) {
      console.log("Coding Conditions: ", !applicant.apct_coding && applicant.apct_coding_complete);
      setMcqCompleted(!applicant.apct_mcq && applicant.apct_mcq_complete);
      setCodingCompleted(!applicant.apct_coding && applicant.apct_coding_complete);
    }
  }, [applicant]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      document.location.reload();
    }
  }, [localStorage.getItem("token")]);

  // Handle starting the MCQ test
  const handleStartMcqTest = () => {
    try {
      // Make Full Screen of Application
      enterFullScreen();
      navigate("/candidate/mcq-test"); // Navigate to MCQ Test
    } catch (error) {
      console.error("Error entering fullscreen for MCQ Test:", error);
    }
  };

  // Handle starting the Coding test
  const handleStartCodingTest = () => {
    try {
      // Make Full Screen of Application
      enterFullScreen();
      navigate("/candidate/coding-test"); // Navigate to Coding Test
    } catch (error) {
      console.error("Error entering fullscreen for Coding Test:", error);
    }
  };

  return (
    <>
      {applicant?.apct_mcq || applicant?.apct_coding || interviewStatus ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {!mcqCompleted && applicant?.apct_mcq ? (
            <Button variant="contained" color="primary" onClick={handleStartMcqTest}>
              Start MCQ Test
            </Button>
          ) : !codingCompleted && applicant?.apct_coding ? (
            <Button variant="contained" color="primary" onClick={handleStartCodingTest}>
              Start Coding Test
            </Button>
          ) : (
            interviewStatus &&
            interviewStatus.apct_stage !== "completed" && (
              <AIInterview userId={user && user.u_id} userConsent={applicant?.apct_user_consent} InterViewStage={interviewStatus.apct_stage} interViewThreadId={applicant?.apct_int_thread_id}/>
            )
          )}
        </Box>
      ) : (
        <Typography variant="subtitle2" textAlign="center" color="primary">
          No tests available.
        </Typography>
      )}

      {interviewStatus && interviewStatus.apct_stage === "completed" && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="subtitle2" textAlign="center" color="primary">
            Your AI interview is completed, HR will get back to you.
          </Typography>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  interviewStatus: state.candidate.interviewStatus,
  applicant: state.applicant?.applicant?.applicant,
  skills: state.applicant?.applicant?.skills,
});

const mapDispatchToProps = { getInterviewStatus, getApplicant };

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDashboard);
