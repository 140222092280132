import {
  GET_ALL_LIST_JOB_SUCCESS,
  GET_JOB_SUCCESS,
  GET_JOBS_SUCCESS,
  CREATE_JOB_SUCCESS,
  UPLOAD_CVS_JOB_SUCCESS,
  UPDATE_JOB_SKILLS_SUCCESS,
  CLEAR_ADD_JOB_DATA,
  GET_HR_DASH_DATA_SUCCESS,
  PARSE_JD_SUCCESS,
  GET_INTERVIEWS_SUCCESS,
  GET_EMPLOYEES_SUCCESS,
  GET_ALL_JOBS_SUCCESS,
  GET_ALL_LIST_COURSES,
  CREATE_AUDIO_VIDEO,
  CLEAR_PARSED_JD,
  CLEAR_CREATED_JD,
  GET_SKILL_SUCCESS
} from "../actions/types";

const initialState = {
  dataList: null,
  jobList: null,
  job: null,
  jobCreated: null,
  cvsUploaded: false,
  jobSkillsUpdated: false,
  hrData: null,
  parsedJDData: null,
  interviewList: null,
  employeesList: null,
  allJobs: [],
  coursesList: [],
  skill: null,
};

const jobs = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_JOBS_SUCCESS:
      return {
        ...state,
        allJobs: payload,
      };
    case GET_SKILL_SUCCESS:
      return {
        ...state,
        skill: payload,
      };
    case CLEAR_PARSED_JD:
      return {
        ...state,
        parsedJDData: null,
      };
    case CLEAR_CREATED_JD:
      return {
        ...state,
        jobCreated: null,
      };
   
    case GET_INTERVIEWS_SUCCESS:
      return {
        ...state,
        interviewList: payload,
      };
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employeesList: payload,
      };
    case CREATE_JOB_SUCCESS:
      return {
        ...state,
        jobCreated: payload,
      };

    case GET_ALL_LIST_JOB_SUCCESS:
      return {
        ...state,
        dataList: payload,
      };
  
    case UPLOAD_CVS_JOB_SUCCESS:
      return {
        ...state,
        cvsUploaded: payload,
      };
    case UPDATE_JOB_SKILLS_SUCCESS:
      return {
        ...state,
        jobSkillsUpdated: true,
      };
    case CLEAR_ADD_JOB_DATA:
      return {
        ...state,
        jobSkillsUpdated: false,
        cvsUploaded: false,
        jobCreated: null,
      };

    case GET_JOBS_SUCCESS:
      return {
        ...state,
        jobList: payload,
      };
    case GET_JOB_SUCCESS:
      return {
        ...state,
        job: payload,
      };
    case GET_HR_DASH_DATA_SUCCESS:
      return {
        ...state,
        hrData: payload,
      };
    case PARSE_JD_SUCCESS:
      return {
        ...state,
        parsedJDData: payload,
      };
    case GET_ALL_LIST_COURSES:
      return {
        ...state,
        coursesList: payload,
      };
    case CREATE_AUDIO_VIDEO:
      return {
        ...state,
        audioVideoList: payload,
      };

    default:
      return state;
  }
};
export default jobs;
