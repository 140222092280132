import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function ServerPaginationGridNoRowCount({
  setPaginationModals,
  paginationModals,
  rowsData,
  getRowId,
  total,
  columns,
  initialState,
}) {
  const [paginationModel, setPaginationModel] = useState(paginationModals);

  useEffect(() => {
    setPaginationModals(paginationModel);
  }, [paginationModel]);

  return (
    <DataGrid
      rows={rowsData}
      getRowId={getRowId}
      columns={columns}
      pageSizeOptions={[5, 10, 25, 50]}
      paginationModel={paginationModel}
      paginationMode="server"
      onPaginationModelChange={setPaginationModel}
      disableRowSelectionOnClick
      rowCount={total}
      initialState={initialState}
      sx={{
        mt: 2,
      }}
    />
  );
}
