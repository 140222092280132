import { useState, useEffect } from "react";
import { Typography, Card, CardContent, Divider, Button, Box, Grid, TextField, Tabs, Tab } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Save";
import { saveQuestionSetAction } from '../../redux/actions/questions';
import { useNavigate, useParams } from "react-router-dom";
import McqQuestion from "./question-set/McqQuestion";
import CodingQuestion from "./question-set/CodingQuestion";
import AiQuestion from "./question-set/AiQuestion";
import { getQuestionSetDetail } from '../../redux/actions/questions';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const CreateQuestionSet = ({ saveQuestionSetAction, question_set, getQuestionSetDetail }) => {
    const { id: qs_id } = useParams();
    const navigate = useNavigate();
    const [mcqs, setMcqs] = useState([]);
    const [codingQuestions, setCodingQuestions] = useState([]);
    const [aiQuestions, setAiQuestions] = useState([]);
    const [value, setValue] = useState(0);
    const { t } = useTranslation();
    const [formData, setFormData] = useState({ setName: '' });

    const handleNameChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const saveQuestionSet = () => {
        if (formData.setName) {
            const dataToSave = {
                setName: formData.setName,
                questions: [
                    ...mcqs.filter(q => q.q_name).map(mcq => ({ ...mcq, type: 'mcq' })),
                    ...codingQuestions.filter(q => q.question).map(coding => ({ ...coding, type: 'coding' })),
                    ...aiQuestions.filter(q => q.question).map(ai => ({ ...ai, type: 'interview' }))
                ]
            };
            saveQuestionSetAction(dataToSave);
            navigate(-1);
        }
    };

    useEffect(() => {
        if (qs_id) {
            getQuestionSetDetail(qs_id);
        }
    }, [qs_id]);

    useEffect(() => {
        if (qs_id && question_set?.mcqQuestions) {
            setMcqs(question_set.mcqQuestions);
        }
        if (qs_id && question_set?.codeQuestions) {
            setCodingQuestions(question_set.codeQuestions);
        }
        if (qs_id && question_set?.aiQuestions) {
            setAiQuestions(question_set.aiQuestions);
        }
        if (qs_id && question_set?.questionSet) {
            setFormData({ ...formData, setName: question_set.questionSet.qs_name });
        }
    }, [question_set]);

    return (
        <>
            <Card raised sx={{ minWidth: "100%" }}>
                <CardContent>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h5">
                            {qs_id ? t('Question Set') : t('Create Question Set')}
                        </Typography>
                        {!qs_id && (<Button
                            variant="text"
                            size={"small"}
                            sx={{ color: "green" }}
                            onClick={saveQuestionSet}
                        >
                            <AddIcon /> Save
                        </Button>)}
                    </Box>
                    <Divider />
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Name:
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            {qs_id ? formData.setName : (<TextField
                                name="setName"
                                placeholder="Question Set Name"
                                value={formData.setName}
                                onChange={handleNameChange}
                            />)}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={12}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label={`MCQ (${mcqs.length})`} {...a11yProps(0)} />
                                    <Tab label={`Coding (${codingQuestions.length})`} {...a11yProps(1)} />
                                    <Tab label={`AI Interview (${aiQuestions.length})`} {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <McqQuestion mcqs={mcqs} setMcqs={setMcqs} qs_id={qs_id} />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <CodingQuestion codingQuestions={codingQuestions} setCodingQuestions={setCodingQuestions} qs_id={qs_id} />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                <AiQuestion aiQuestions={aiQuestions} setAiQuestions={setAiQuestions} qs_id={qs_id} />
                            </CustomTabPanel>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

const mapStateToProps = (state) => ({
    question_set: state.questions.question_set,
});

const mapDispatchToProps = {
    saveQuestionSetAction, getQuestionSetDetail
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuestionSet);
