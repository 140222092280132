import { useState } from "react";
import { Grid, TextField, Typography, Button, Card } from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
const CodingQuestion = ({ codingQuestions, setCodingQuestions, qs_id }) => {
    const handleAddMoreCodingQuestions = () => {
        setCodingQuestions([...codingQuestions, { q_id: uuidv4(), question: "" }]);
    };

    const handleRemoveQuestion = (id) => {
        setCodingQuestions(codingQuestions.filter(q => q.q_id !== id));
    };

    const handleQuestionChange = (id, value) => {
        setCodingQuestions(codingQuestions.map(q => (q.q_id === id ? { ...q, question: value } : q)));
    };

    return (
        <>
            {codingQuestions.map((q) => (
                <Card key={q.id} sx={{ mb: 2 }}>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Coding Question:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {qs_id ? q.q_name : (<TextField
                                fullWidth
                                value={q.q_name}
                                onChange={(e) => handleQuestionChange(q.q_id, e.target.value)}
                                placeholder="Enter coding question here"
                            />)}
                        </Grid>
                        {(!qs_id && codingQuestions.length > 0) && (
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => handleRemoveQuestion(q.q_id)}
                                >
                                    Remove
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Card>
            ))}
            {!qs_id && (<Grid item xs={12} sx={{ mt: 2 }}>
                <Button variant="contained" color="primary" onClick={handleAddMoreCodingQuestions}>
                    Add Coding Questions
                </Button>
            </Grid>)}
        </>
    );
};

export default CodingQuestion;
