import { useState, useEffect } from "react";
import {
  Typography,
  Card,
  CardContent,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Slider,
  Select,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Stack,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import {
  getCandidates,
  sendInvite,
  addHrRemarks,
  getCandidatesByBatchId,
  releaseOffer,
  scheduleSecRound,
  getJobsList,
} from "../../redux/actions/candidate";
import AlertDialog from "../../components/Dialog";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

// Import Material-UI icons
import SmartDisplayTwoToneIcon from "@mui/icons-material/SmartDisplayTwoTone";
import DownloadIcon from "@mui/icons-material/Download";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import EditIcon from "@mui/icons-material/Edit";
import { styled, alpha } from "@mui/system";
import ServerPaginationGridNoRowCount from "../../components/Table/DataGrid";
import {
  getApplicantReport,
  viewApplicantReport,
  deleteApplicant,
} from "../../redux/actions/applicant";
import { formatDate } from "../../utils/dateUtils";
import { getNearvalue } from "../../utils";
import SendInviteDialog from '../common/SendInviteDialog'; // Import the new component
import { useTranslation } from "react-i18next";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 550px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

const stages = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "applied",
    label: "Applied",
  },
  {
    value: "invited",
    label: "Invited",
  },
  {
    value: "in progress",
    label: "In Progress",
  },
  {
    value: "completed",
    label: "Completed",
  },
];

export const Candidates = ({
  getCandidates,
  getCandidatesByBatchId,
  candidates,
  sendInvite,
  addHrRemarks,
  releaseOffer,
  getApplicantReport,
  viewApplicantReport,
  scheduleSecRound,
  jDID,
  bT_ID,
  deleteApplicant,
  getJobsList,
  jobsList,
}) => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupRemark, setShowPopupRemark] = useState(false);
  const [remarkText, setRemarkText] = useState("");
  const [searchParams] = useSearchParams();
  const jb_id = searchParams.get("jobId") || null;
  const bt_id = searchParams.get("batchId") || null;
  const [paginationModals, setPaginationModals] = useState({
    page: 0,
    pageSize: 50,
  });
  const [apctID, setApctID] = useState(null);
  const [remark, setRemark] = useState(null);
  const [showPopupSecRound, setShowPopupSecRound] = useState(false);
  const [secRoundApctId, setSecRoundApctId] = useState(null);
  const [email, setEmail] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [titleDelete, setTitleDelete] = useState("");
  const [contentDelete, setContentDelete] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [tableHeight, setTableHeight] = useState(400);
  const [valueAIScore, setValueAIScore] = useState([0, 100]);
  const [valueMatchRate, setValueMatchRate] = useState([0, 99]);
  const [valueExperience, setValueExperience] = useState([0, 100]);
  const [stage, setStage] = useState("all");
  const [searchKey, setSearchKey] = useState(null);
  const [filters, setFilters] = useState(null);
  const [selectedJob, setSelectedJob] = useState("All");
  const { t, i18n } = useTranslation();

  //all invite model related states
  const [showSendInviteModal, setShowSendInviteModal] = useState(false);
  const [selectedCandidateId, setSelectedCandidateId] = useState(null);
  const [mcqChecked, setMcqChecked] = useState(false);
  const [codeChecked, setCodeChecked] = useState(false);
  const [aiAssistanceCheck, setAiAssistanceCheck] = useState(true);
  const [emailContent, setEmailContent] = useState('');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [mcqCount, setMcqCount] = useState(20);
  const [codeCount, setCodeCount] = useState(2);
  const [mcqError, setMcqError] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [mcqTime, setMcqTime] = useState(15);
  const [codeTime, setCodeTime] = useState(15);
  const [aiInterviewTime, setAiInterviewTime] = useState(15);


  // all invite model related functions
  const handleCloseSendInviteModal = () => {
    setShowSendInviteModal(false);
    setSelectedCandidateId(null);
  };

  const openConfirmationDialog = () => {
    setShowConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    setShowConfirmationDialog(false);
  };


  const confirmAndSendInvite = () => {
    // sendInviteCandidate(params.row.apct_id);
    let bodyData = {
      mcq: mcqChecked,
      coding: codeChecked,
      interview: aiAssistanceCheck,
      email_body: emailContent,
      mcqCount: mcqCount,
      codeCount: codeCount,
      mcqTime: mcqTime,
      codeTime: codeTime,
      aiInterviewTime: aiInterviewTime
    };
    sendInvite(
      selectedCandidateId,
      jDID || jb_id,
      bt_id || bT_ID,
      paginationModals.page,
      paginationModals.pageSize,
      bodyData
    );
    setShowConfirmationDialog(false);
    setShowSendInviteModal(false);
    setSelectedCandidateId(null);
  };

  const handleMcqCountChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setMcqCount(value);
    if (value >= 10 && value <= 100) {
      setMcqError(false);
    } else {
      setMcqError(true);
    }
  };

  const handleCodeCountChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setCodeCount(value);
    if (value >= 2 && value <= 10) {
      setCodeError(false);
    } else {
      setCodeError(true);
    }
  };

  // Validation handlers for time settings
  const handleMcqTimeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 15) {
      setMcqTime(value);
    }
  };

  const handleCodeTimeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 15) {
      setCodeTime(value);
    }
  };

  const handleAiInterviewTimeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 15) {
      setAiInterviewTime(value);
    }
  };

  const openSendInviteModal = (candidateId) => {
    setMcqChecked(false);
    setCodeChecked(false);
    setEmailContent(`<p>Hi {{userName}},</p>
                <p>You have been invited for an AI Interview for the position of {{jobName}}.</p>
                <p>Please find below details:</p>
                <p>Username: {{userEmail}}</p>
                <p>Password: {{userPassword}}</p>               
                <p>Please click on below link to start interview</p>
                <a href="{{link}}" target="_blank">Click Here</a>
                <div>Thank you</div>
                <div>Team</div>
                <div>Girikon</div>`);
    setSelectedCandidateId(candidateId);
    setShowSendInviteModal(true);
  };

  // End of send invite related functions and states


  const rowHeight = 52;
  const updateTableHeight = (pageSize) => {
    const headerHeight = 56; // Approximate header height (adjust based on your actual header height)
    const footerHeight = 56; // Approximate footer height (adjust based on your actual footer height)
    const newHeight = headerHeight + footerHeight + rowHeight * pageSize;
    setTableHeight(newHeight);
  };

  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectRow = (id) => {
    setSelectedRows((prev) => {
      if (prev.includes(id)) {
        return prev.filter((rowId) => rowId !== id); // Deselect row
      } else {
        return [...prev, id]; // Select row
      }
    });
  };

  const columns = [
    {
      field: "selection",
      headerName: "",
      width: 50,
      renderCell: (params) => (
        <Checkbox
          checked={selectedRows.includes(params.row.apct_id)}
          onChange={() => handleSelectRow(params.row.apct_id)}
        />
      ),
    },
    {
      field: "apct_name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Link to={`/hr/candidates/candidate-profile/${params.row.apct_id}`}>
          {params.row.apct_name}
        </Link>
      ),
    },
    {
      field: "job_name",
      headerName: "Job Description",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Link to={`/hr/jd-detail/${params.row.job_id}`}>
          {params.row.job_name}
        </Link>
      ),
    },
    {
      field: "apct_exp",
      headerName: "Exp",
      flex: 1,
      renderCell: (params) => (
        <>{params.row.apct_exp ? parseInt(params.row.apct_exp) : "-"}</>
      ),
    },
    {
      field: "apct_match_rate",
      headerName: "CV Match (%)",
      flex: 1,
      renderCell: (params) => <>{params.row.apct_match_rate}</>,
      sortComparator: (v1, v2, param1, param2) => {
        const val1 = parseFloat(v1.replace("%", ""));
        const val2 = parseFloat(v2.replace("%", ""));
        return val1 - val2;
      },
    },

    {
      field: "apct_int_url_sent",
      headerName: "Invite",
      flex: 1,
      renderCell: (params) => (
        <Button
          onClick={() => openSendInviteModal(params.row.apct_id)}
          disabled={params.row.apct_int_url_sent}
        >
          {params.row.apct_int_url_sent ? "Sent" : "Send"}
        </Button>
      ),
    },
    {
      field: "intw_rating",
      headerName: "Assistant Score",
      flex: 1,
      renderCell: (params) => (
        params.row.apct_intw_rating ? <Link to={`/hr/ai_score/${params.row.apct_id}`}>
          {`${params.row.apct_intw_rating}/10`}
        </Link> : '-'
      ),
    },
    {
      field: "apct_second_round",
      headerName: "2nd Round",
      flex: 1,
      renderCell: (params) => (
        <Button
          onClick={() => setSecRoundApctId(params.row.apct_id)}
          disabled={!params.row.apct_intw_rating}
        >
          {params.row.apct_second_round ? "Scheduled" : "Schedule"}
        </Button>
      ),
    },
    {
      field: "apct_hr_remarks",
      headerName: "Remark",
      flex: 1,
      renderCell: (params) => (
        <Button
          onClick={
            params.row.apct_hr_remarks
              ? () => setRemark(params.row.apct_hr_remarks)
              : () => setApctID(params.row.apct_id)
          }
        >
          {params.row.apct_hr_remarks ? "View" : "Add"}
        </Button>
      ),
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <>
    //       {params.row.apct_intw_rating ? (
    //         <Link
    //           to={`/hr/candidates/interview-details/${params.row.apct_user_id_fk}/${params.row.apct_int_thread_id}`}
    //         >
    //           <IconButton sx={{ color: "#f70000" }}>
    //             <SmartDisplayTwoToneIcon />
    //           </IconButton>
    //         </Link>
    //       ) : (
    //         <IconButton disabled>
    //           <SmartDisplayTwoToneIcon />
    //         </IconButton>
    //       )}

    //       <IconButton
    //         disabled={!params.row.apct_intw_rating}
    //         onClick={() => getApplicantReport(params.row.apct_id)}
    //       >
    //         <DownloadIcon />
    //       </IconButton>
    //     </>
    //   ),
    // },
    {
      field: "apct_offer_released",
      headerName: "Offer",
      flex: 1,
      renderCell: (params) => (
        <>
          <Button
            onClick={() => offerReleased(params.row.apct_id)}
            disabled={
              params.row.apct_offer_released || !params.row.apct_intw_rating
            }
          >
            {params.row.apct_offer_released ? "Released" : "Release"}
          </Button>
        </>
      ),
    },
    // {
    //   field: "Edit",
    //   headerName: "Edit",
    //   flex: 1,
    //   renderCell: (params) => (
    // <Button
    //   component={Link}
    //   to={`/hr/candidates/candidate-profile/${params.row.apct_id}?edit=true`}
    //   variant="text"
    //   color="primary"
    //   size={"small"}
    // >
    //   <EditIcon />
    // </Button>
    //   ),
    // },
    {
      field: "bt_created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => (
        <>
          {params && params.row && params.row.apct_created_at
            ? formatDate(
              JSON.parse(localStorage.getItem("user")),
              params.row.apct_created_at
            )
            : ""}
        </>
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 180,
      renderCell: (params) => (
        <>
          {
          params.row.apct_stage == "completed" || params.row.apct_stage == "Partial" ? (
            <>
              <Link
                to={`/hr/candidates/interview-details/${params.row.apct_user_id_fk}/${params.row.apct_int_thread_id}`}
              >
                <IconButton sx={{ color: "#f70000" }}>
                  <SmartDisplayTwoToneIcon />
                </IconButton>
              </Link>
              <IconButton
                disabled={!params.row.apct_intw_rating}
                onClick={() => getApplicantReport(params.row.apct_id)}
              >
                <DownloadIcon />
              </IconButton>
              <IconButton>
                <Link
                  to={`/hr/candidates/candidate-profile/${params.row.apct_id}?edit=true`}
                  color="primary"
                >
                  <EditIcon />
                </Link>
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                onClick={() =>
                  submitData(params.row.apct_id, params.row.apct_name)
                }
              >
                <DeleteForeverIcon sx={{ color: "red" }} />
              </IconButton>
              <IconButton>
                <Link
                  to={`/hr/candidates/candidate-profile/${params.row.apct_id}?edit=true`}
                  color="primary"
                >
                  <EditIcon />
                </Link>
              </IconButton>
            </>
          )}
        </>
      ),
    },

    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   renderCell: (params) => (
    //     <>
    //       {params.row.apct_stage === "applied" ? (
    // <IconButton
    //   onClick={() => submitData(params.row.apct_id, params.row.apct_name)}
    // >
    //   <DeleteForeverIcon sx={{ color: "red" }} />
    // </IconButton>,
    //       ) : (
    //         ""
    //       )}
    //     </>
    //   ),
    // },
  ];

  const submitData = async (id, name) => {
    setApctID(id);
    setTitleDelete("Delete Applicant");
    setContentDelete(`Are you sure you want to delete applicant :  ${name}?`);
    setOpenDelete(true);
  };

  useEffect(() => {
    getJobsList();
  }, []);

  useEffect(() => {
    if (confirmDelete && apctID) {
      deleteApplicant(
        apctID,
        null,
        paginationModals.page,
        paginationModals.pageSize
      );
      setConfirmDelete(false);
    }
  }, [confirmDelete, apctID]);

  useEffect(() => {
    if (remark) {
      setShowPopupRemark(true);
    }
  }, [remark]);

  useEffect(() => {
    if (apctID && !openDelete) {
      setShowPopup(true);
    }
  }, [apctID]);

  useEffect(() => {
    if (secRoundApctId) {
      setShowPopupSecRound(true);
    }
  }, [secRoundApctId]);

  const scheduleSecondRound = async () => {
    await scheduleSecRound({
      apct_id: secRoundApctId,
      email: email,
    });
    setSecRoundApctId(null);
    setEmail(null);
    setShowPopupSecRound(false);
  };

  const onCancelSchedule = () => {
    setSecRoundApctId(null);
    setEmail(null);
    setShowPopupSecRound(false);
  };

  useEffect(() => {
    if (selectedJob === "All") {
      getCandidates(null, paginationModals.page, paginationModals.pageSize);
    } else {
      if (bt_id || bT_ID) {
        getCandidatesByBatchId(
          bt_id || bT_ID,
          paginationModals.page,
          paginationModals.pageSize
        );
      }

      if (jDID || jb_id) {
        getCandidates(
          jDID || jb_id,
          paginationModals.page,
          paginationModals.pageSize
        );
      } else {
        if (filters) {
          getCandidates(
            null,
            paginationModals.page,
            paginationModals.pageSize,
            filters,
            null
          );
        } else if (searchKey) {
          setTimeout(() => {
            let search = `&search=${searchKey}`;
            getCandidates(
              null,
              paginationModals.page,
              paginationModals.pageSize,
              null,
              search
            );
          }, 2000);
        } else {
          getCandidates(null, paginationModals.page, paginationModals.pageSize);
        }
      }
    }
  }, [bt_id, bT_ID, jDID, jb_id, paginationModals, filters, searchKey]);

  const sendInviteCandidate = (apct_id) => {
    sendInvite(
      apct_id,
      jDID || jb_id,
      bt_id || bT_ID,
      paginationModals.page,
      paginationModals.pageSize
    );
  };

  const handleRemarkSubmit = (apct_id) => {
    let data = {
      hr_remarks: remarkText,
      apct_id: apct_id,
    };
    addHrRemarks(
      data,
      jDID || jb_id,
      bt_id || bT_ID,
      paginationModals.page,
      paginationModals.pageSize
    );
    setShowPopup(false);
    setApctID(null);
  };

  const onClickCloseRemark = () => {
    setApctID(null);
    setShowPopup(false);
    setRemark(null);
  };

  const offerReleased = (apct_id) => {
    releaseOffer(
      apct_id,
      jDID || jb_id,
      bt_id || bT_ID,
      paginationModals.page,
      paginationModals.pageSize
    );
  };

  const onClickClosePopup = () => {
    setShowPopup(false);
    setApctID(null);
  };

  useEffect(() => {
    updateTableHeight(candidates?.rows?.length);
  }, [paginationModals.pageSize, candidates]);

  const handleChangeAIScore = (event, newValue) => {
    setValueAIScore(newValue);
  };
  const handleChangeMatchRate = (event, newValue) => {
    setValueMatchRate(newValue);
  };
  const handleChangeExperience = (event, newValue) => {
    setValueExperience(newValue);
  };

  const filetrData = async () => {
    let selectedJobId = jobsList.find((job) => job.value === selectedJob).id
      ? jobsList.find((job) => job.value === selectedJob).id
      : null;
    setSearchKey(null);
    let filterss = `&minAS=${getNearvalue(
      valueAIScore[0],
      10
    )}&maxAS=${getNearvalue(valueAIScore[1], 10)}&minR=${valueMatchRate[0]
      }&maxR=${valueMatchRate[1]}&minE=${getNearvalue(
        valueExperience[0],
        4
      )}&maxE=${getNearvalue(valueExperience[1], 4)}&stage=${stage}&job=${selectedJobId === null ? "all" : selectedJobId
      }`;

    // console.log("filterss", selectedJobId);
    setFilters(filterss);
  };

  const clearFilters = () => {
    navigate(0);
  };

  const clearSearch = () => {
    navigate(0);
  };

  const onChangeSelectedJob = (e, value, reason) => {
    if (reason === "clear") {
      setSelectedJob("");
    } else {
      setSelectedJob(value.value);
    }
  };

  const onChangeSelectStage = (e, value, reason) => {
    if (reason === "clear") {
      setStage("");
    } else {
      setStage(value.value);
    }
  };

  return (
    <>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        title={title}
        setConfirm={setConfirm}
        content={content}
      />

      <AlertDialog
        open={openDelete}
        setOpen={setOpenDelete}
        title={titleDelete}
        setConfirm={setConfirmDelete}
        content={contentDelete}
      />

      <SendInviteDialog
        open={showSendInviteModal}
        handleClose={handleCloseSendInviteModal}
        confirmAndSendInvite={confirmAndSendInvite}
        openConfirmationDialog={openConfirmationDialog}
        closeConfirmationDialog={closeConfirmationDialog}
        mcqChecked={mcqChecked}
        setMcqChecked={setMcqChecked}
        codeChecked={codeChecked}
        setCodeChecked={setCodeChecked}
        aiAssistanceCheck={aiAssistanceCheck}
        setAiAssistanceCheck={setAiAssistanceCheck}
        emailContent={emailContent}
        setEmailContent={setEmailContent}
        mcqCount={mcqCount}
        handleMcqCountChange={handleMcqCountChange}
        mcqError={mcqError}
        codeCount={codeCount}
        handleCodeCountChange={handleCodeCountChange}
        codeError={codeError}
        mcqTime={mcqTime}
        handleMcqTimeChange={handleMcqTimeChange}
        codeTime={codeTime}
        handleCodeTimeChange={handleCodeTimeChange}
        aiInterviewTime={aiInterviewTime}
        handleAiInterviewTimeChange={handleAiInterviewTimeChange}
        showConfirmationDialog={showConfirmationDialog}
      />

      <Card raised sx={{ minWidth: "100%" }}>
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5">{t('Filters')}</Typography>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="text"
                size={"small"}
                color="primary"
                onClick={filetrData}
              >
                Apply
              </Button>
              {filters && (
                <Button
                  variant="text"
                  size={"small"}
                  color="primary"
                  onClick={clearFilters}
                >
                  Clear
                </Button>
              )}
            </Stack>
          </Box>
          <Divider />
          <Grid
            container
            xs={12}
            sm={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: 1,
            }}
          >
            <Grid item xs={12} sm={2.5}>
              <FormControl fullWidth>
                <Autocomplete
                  options={jobsList && jobsList}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Job" />
                  )}
                  // onChange={(e, value) => setSelectedJob(value.value)}
                  onChange={onChangeSelectedJob}
                  sx={{ width: 200 }}
                />
              </FormControl>
              {/* <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Job</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedJob}
                  label="Stage"
                  onChange={(e) => setSelectedJob(e.target.value)}
                >
                  {jobsList && jobsList.map((stage) => (
                    <MenuItem value={stage.value}>{stage.label}</MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </Grid>
            <Grid item xs={12} sm={2.5} sx={{ ml: 29 }}>
              <FormControl fullWidth>
                <Autocomplete
                  options={stages}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Stage" />
                  )}
                  // onChange={(e, value) => setStage(value.value)}
                  onChange={onChangeSelectStage}
                  sx={{ width: 200 }}
                />
              </FormControl>
              {/* <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Stage</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={stage}
                  label="Stage"
                  onChange={(e) => setStage(e.target.value)}
                >
                  {stages.map((stage) => (
                    <MenuItem value={stage.value}>{stage.label}</MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </Grid>

            {/* <Grid item xs={12} sm={2} sx={{ textAlign: "center" }}>
              <Button variant="contained" color="primary">
                Apply
              </Button>
            </Grid> */}
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: 1,
              pl: 2,
              pr: 2,
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12} sm={2.5}>
              <Typography variant="subtitle2">AI Score</Typography>

              <Slider
                getAriaLabel={() => "Temperature range"}
                value={valueAIScore}
                onChange={handleChangeAIScore}
              // valueLabelDisplay="auto"
              // getAriaValueText={valuetext}
              />
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography>{getNearvalue(valueAIScore[0], 10)}</Typography>
                <Typography> {getNearvalue(valueAIScore[1], 10)}</Typography>
              </Stack>
            </Grid>

            {/* <Grid item xs={12} sm={2}>
              <Slider
                getAriaLabel={() => "Temperature range"}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
              />
            </Grid> */}
            <Grid item xs={12} sm={2.5}>
              <Typography variant="subtitle2">Match Rate</Typography>

              <Slider
                getAriaLabel={() => "Temperature range"}
                value={valueMatchRate}
                onChange={handleChangeMatchRate}
              // getAriaValueText={valuetext}
              />
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography>{valueMatchRate[0]}</Typography>
                <Typography>{valueMatchRate[1]}</Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={2.5}>
              <Typography variant="subtitle2">Experience</Typography>

              <Slider
                getAriaLabel={() => "Temperature range"}
                value={valueExperience}
                onChange={handleChangeExperience}

              // getAriaValueText={valuetext}
              />
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography>{getNearvalue(valueExperience[0], 4)}</Typography>
                <Typography>{getNearvalue(valueExperience[1], 4)}</Typography>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card raised sx={{ minWidth: "100%" }}>
        <CardContent>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5">Candidates</Typography>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {/* <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  value={searchKey ? searchKey : ""}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
              </Search>

              {searchKey && (
                <IconButton onClick={clearSearch}>
                  <CloseIcon />
                </IconButton>
              )} */}

              <Button
                variant="text"
                size={"small"}
                color="primary"
                disabled={selectedRows.length <= 0}
                onClick={() => openSendInviteModal(selectedRows)}
              >
                Send Bulk Invite
              </Button>

            </Stack>
          </Stack>
          <Divider />
          <Dialog
            open={showPopupSecRound}
            onClose={() => onCancelSchedule()}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Schedule Second Round</DialogTitle>
            <DialogContent>
              <Typography>Enter Interviewer's Email:</Typography>
              <TextField
                type="email"
                autoFocus
                fullWidth
                minWidth="sm"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => onCancelSchedule()}>Cancel</Button>
              <Button
                onClick={() => scheduleSecondRound()}
                color="primary"
                disabled={!email}
              >
                Schedule
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={showPopup}
            onClose={() => setShowPopup(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Add Remark</DialogTitle>
            <DialogContent>
              <Textarea
                minRows={4}
                autoFocus
                fullWidth
                minWidth="sm"
                value={remarkText}
                onChange={(e) => setRemarkText(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => onClickCloseRemark()}>Cancel</Button>
              <Button
                onClick={() => handleRemarkSubmit(apctID)}
                color="primary"
                disabled={!remarkText}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={showPopupRemark}
            onClose={() => setShowPopupRemark(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>View Remark</DialogTitle>
            <DialogContent>
              <Typography>{remark}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowPopupRemark(false)}>Okay</Button>
            </DialogActions>
          </Dialog>
          <div style={{ height: tableHeight, width: "100%" }}>
            <ServerPaginationGridNoRowCount
              setPaginationModals={setPaginationModals}
              paginationModals={paginationModals}
              rowsData={candidates && candidates.rows ? candidates.rows : []}
              total={candidates && candidates.count ? candidates.count : 0}
              columns={columns}
              initialState={{
                sorting: {
                  sortModel: [
                    {
                      field: "apct_match_rate",
                      sort: "desc",
                    },
                  ],
                },
              }}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  candidates: state.candidate.candidateLists,
  applicant_resume: state.applicant.applicant_resume,
  applicant_video: state.applicant.applicant_video,
  jobsList: state.candidate.jobsList,
});

const mapDispatchToProps = {
  getCandidates,
  sendInvite,
  addHrRemarks,
  getCandidatesByBatchId,
  releaseOffer,
  getApplicantReport,
  viewApplicantReport,
  scheduleSecRound,
  deleteApplicant,
  getJobsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Candidates);
