import {
  LOGIN_CANDIDATE_SUCCESS,
  GET_CANDIDATE_DASHBOARD_SUCCESS,
  START_INTERVIEW_SUCCESS,
  SUBMIT_INTERVIEW_QUESTION_SUCCESS,
  END_INTERVIEW_SUCCESS,
  GET_CANDIDATES_SUCCESS,
  ADD_HR_REMARKS_SUCCESS,
  GET_INTERVIEW_STATUS_SUCCESS,
  GET_CANDIDATES_JD_SUCCESS,
  GET_CANDIDATES_BY_BATCHID_SUCCESS,
  GET_QA_BY_THREADID_SUCCESS,
  GET_JOBSLIST_SUCCESS,
  UPDATE_USER_CONSENT
} from "../actions/types";

const initialState = {
  isCandLoggedIn: null,
  candidateData: null,
  interviewData: null,
  candidateLists: null,
  candidateJDLists: null,
  candidateBatchLists: null,
  submitAnsRes: null,
  startInterviewResRecvd: false,
  interviewEnded: false,
  hrRemark: null,
  interviewStatus: null,
  qaData: null,
  jobsList : null,
  userConsent: false,
};

const candidate = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_QA_BY_THREADID_SUCCESS:
      return {
        ...state,
        qaData: payload,
      };
    
    case GET_JOBSLIST_SUCCESS:
      return {
        ...state,
        jobsList: payload,
      };
    case LOGIN_CANDIDATE_SUCCESS:
      return {
        ...state,
        isCandLoggedIn: true,
        candidateData: payload,
      };
    case GET_INTERVIEW_STATUS_SUCCESS:
      return {
        ...state,
        interviewStatus: payload,
      };

    case GET_CANDIDATE_DASHBOARD_SUCCESS:
      return {
        ...state,
        candidateData: payload,
      };

    case END_INTERVIEW_SUCCESS:
      return {
        ...state,
        interviewEnded: true,
      };

    case START_INTERVIEW_SUCCESS:
      return {
        ...state,
        interviewData: payload,
        startInterviewResRecvd: true,
      };
    case GET_CANDIDATES_SUCCESS:
      return {
        ...state,
        candidateLists: payload,
      };
    case GET_CANDIDATES_JD_SUCCESS:
      return {
        ...state,
        candidateJDLists: payload,
      };
    case GET_CANDIDATES_BY_BATCHID_SUCCESS:
      return {
        ...state,
        candidateBatchLists: payload,
      };
    
    case SUBMIT_INTERVIEW_QUESTION_SUCCESS:
      return {
        ...state,
        submitAnsRes: payload,
      };

    case ADD_HR_REMARKS_SUCCESS:
      return {
        ...state,
        hrRemark: payload,
      };
    case UPDATE_USER_CONSENT:
      return {
        ...state,
        userConsent: payload,
      };

    default:
      return state;
  }
};
export default candidate;
