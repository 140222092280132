import DashboardIcon from "@mui/icons-material/Dashboard";
import LayersIcon from "@mui/icons-material/Layers";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import LabelIcon from "@mui/icons-material/Label";
import WorkIcon from "@mui/icons-material/Work";
import AddIcon from "@mui/icons-material/Add";
import GroupsIcon from "@mui/icons-material/Groups";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SummarizeIcon from "@mui/icons-material/Summarize";
import QueueIcon from "@mui/icons-material/Queue";
import ViewListIcon from "@mui/icons-material/ViewList";
import { Link } from "react-router-dom";

export const roles = [
  {
    id: 1,
    value: "admin",
    label: "Admin",
    menus: [
      {
        id: 2,
        value: "dashboard",
        label: "Dashboard",
        path: "/admin/dashboard",
        icon: <DashboardIcon />,
        breadCrum: (
          <>
            Amdin/<Link to="/admin/dashboard">Amdin/Dashboard</Link>
          </>
        ),
      },
      {
        id: 3,
        value: "jobs",
        label: "Jobs",
        path: "/admin/jobs",
        icon: <WorkIcon />,
        breadCrum: (
          <>
            Amdin/
            <Link to="/admin/jobs">Jobs</Link>
          </>
        ),
        submenu: [
          {
            id: 4,
            value: "add job",
            label: "Add Job",
            path: "/admin/jobs/add-job",
            icon: <AddIcon />,

            breadCrum: (
              <>
                Amdin/
                <Link to="/admin/jobs">Jobs</Link> /
                <Link to="/admin/jobs/add-job">Add Job</Link>
              </>
            ),
          },
        ],
      },
      {
        id: 5,
        value: "batches",
        label: "Batches",
        path: "/admin/batches",
        icon: <LayersIcon />,
        breadCrum: (
          <>
            Amdin/
            <Link to="/admin/batches">Batches</Link>
          </>
        ),

        submenu: [
          {
            id: 6,
            value: "add batch",
            label: "Add Batch",
            path: "/hr/batches/add-batch",
            icon: <AddIcon />,
            breadCrum: (
              <>
                Amdin/
                <Link to="/admin/batches">Batches</Link> /
                <Link to="/admin/batches/add-batch">Add Batch</Link>
              </>
            ),
          },
        ],
      },
      {
        id: 7,
        value: "candidates",
        label: "Candidates",
        path: "/admin/candidates",
        icon: <GroupsIcon />,
        breadCrum: (
          <>
            Amdin/
            <Link to="/admin/candidates">Candidates</Link>
          </>
        ),
      },

      {
        id: 8,
        value: "interviews",
        label: "Interviews",
        path: "/admin/interviews",
        icon: <AssessmentIcon />,
        breadCrum: (
          <>
            Amdin/
            <Link to="/admin/interviews">Interviews</Link>
          </>
        ),
      },
    ],
  },
  {
    id: 9,
    value: "hr",
    label: "HR",

    menus: [
      {
        id: 10,
        value: "dashboard",
        label: "Dashboard",
        path: "/hr/dashboard",
        icon: <DashboardIcon />,
        breadCrum: (
          <>
            HR/<Link to="/hr/dashboard">Dashboard</Link>
          </>
        ),
      },
      {
        id: 11,
        value: "jd list",
        label: "JD List",
        path: "/hr/jd-list",
        slug: 'jd-read',
        icon: <WorkIcon />,
        breadCrum: (
          <>
            HR/
            <Link to="/hr/jobs">Jobs</Link>
          </>
        ),
        // submenu: [
        //   {
        //     id: 12,
        //     value: "create jd",
        //     label: "Create JD",
        //     path: "/hr/jd-list/create-jd",
        //     icon: <AddIcon />,
        //     breadCrum: (
        //       <>
        //         HR/
        //         <Link to="/hr/jobs">Jobs</Link> /
        //         <Link to="/hr/jobs/add-job">Add Job</Link>
        //       </>
        //     ),
        //   },
        // ],
      },
      {
        id: 13,
        value: "batches",
        label: "Batches",
        path: "/hr/batches",
        slug: 'batch-read',
        icon: <LayersIcon />,
        breadCrum: (
          <>
            HR/
            <Link to="/hr/batches">Batches</Link>
          </>
        ),
        // submenu: [
        //   {
        //     id: 14,
        //     value: "add batch",
        //     label: "Add Batch",
        //     path: "/hr/batches/add-batch",
        //     icon: <AddIcon />,
        //     breadCrum: (
        //       <>
        //         HR/
        //         <Link to="/hr/batches">Batches</Link> /
        //         <Link to="/hr/batches/add-batch">Add Batch</Link>
        //       </>
        //     ),
        //   },
        // ],
      },
      {
        id: 15,
        value: "candidates",
        label: "Candidates",
        slug: 'candidate-read',
        path: "/hr/candidates",
        icon: <GroupsIcon />,
        breadCrum: (
          <>
            HR/
            <Link to="/hr/candidates">Candidates</Link>
          </>
        ),
      },

      {
        id: 16,
        value: "interviews",
        label: "Interviews",
        slug: 'interview-read',
        path: "/hr/interviews",
        icon: <AssessmentIcon />,
        breadCrum: (
          <>
            HR/
            <Link to="/hr/interviews">Interviews</Link>
          </>
        ),
      },
      // {
      //   id: 17,
      //   value: "employees",
      //   label: "Employees",
      //   slug: 'employee-read',
      //   path: "/hr/employees",
      //   icon: <PeopleAltIcon />,
      //   breadCrum: (
      //     <>
      //       HR/
      //       <Link to="/hr/employees">Employees</Link>
      //     </>
      //   ),
      // },
      {
        id: 17,
        value: "skills",
        label: "Skills",
        slug: 'skill-read',
        path: "/hr/skills",
        icon: <AutoStoriesIcon />,
        breadCrum: (
          <>
            HR/
            <Link to="/hr/skills">Skills</Link>
          </>
        ),
      },
      {
        id: 17,
        value: "label",
        label: "Label",
        slug: 'label-read',
        path: "/hr/label",
        icon: <LabelIcon />,
        breadCrum: (
          <>
            HR/
            <Link to="/hr/label">Label</Link>
          </>
        ),
      },
      {
        id: 28,
        value: "Access Control",
        label: "Access Control",
        slug: 'access-read',
        path: "/hr/access-control",
        icon: <SummarizeIcon />,
        breadCrum: (
          <>
            Hr/
            <Link to="/hr/access-control">Access Control</Link>
          </>
        ),
      },
      {
        id: 28,
        value: "Question Set",
        label: "Question Set",
        slug: 'question-read',
        path: "/hr/question-set",
        icon: <QueueIcon />,
        breadCrum: (
          <>
            Hr/
            <Link to="/hr/question-set">Question Set</Link>
          </>
        ),
      },
    ],
  },
  {
    id: 18,
    value: "candidate",
    label: "Candidate",
    menus: [
      {
        id: 19,
        value: "dashboard",
        label: "Dashboard",
        path: "/candidate/dashboard",
        icon: <DashboardIcon />,
        breadCrum: (
          <>
            Candidate/<Link to="/candidate/dashboard">Dashboard</Link>
          </>
        ),
      },
    ],
  },
  {
    id: 20,
    value: "employee",
    label: "Employee",
    menus: [
      {
        id: 21,
        value: "dashboard",
        label: "Dashboard",
        path: "/employee/dashboard",
        icon: <DashboardIcon />,
        breadCrum: (
          <>
            Employee/<Link to="/employee/dashboard">Dashboard</Link>
          </>
        ),
      },
      {
        id: 22,
        value: "skills",
        label: "My Skills",
        path: "/employee/my-skills",
        icon: <ViewListIcon />,
        breadCrum: (
          <>
            Employee/
            <Link to="/employee/my-skills">Skills</Link>
          </>
        ),
      },
      // {
      //   id: 6765,
      //   value: "assitant",
      //   label: "AI Assistant",
      //   path: "/employee/ai-assistant",
      //   icon: <AssessmentIcon />,
      //   breadCrum: (
      //     <>
      //       Employee/
      //       <Link to="/employee/ai-assistant">AI Assistant</Link>
      //     </>
      //   ),
      // },
      // {
      //   id: 23,
      //   value: "self-assessments",
      //   label: "Self Assessments",
      //   path: "/employee/self-assessments",
      //   icon: <AssessmentIcon />,
      //   breadCrum: (
      //     <>
      //       Employee/
      //       <Link to="/employee/self-assessments">Self Assessments</Link>
      //     </>
      //   ),
      // submenu: [
      //   {
      //     id: 25,
      //     value: "add sklills",
      //     label: "My Skills",
      //     path: "/employee/self-assessment/add-skills",
      //     icon: <AddIcon />,
      //     breadCrum: (
      //       <>
      //         Employee/
      //         <Link to="/employee/self-assessment">Self Assessment</Link> /
      //         <Link to="/employee/self-assessment/add-skills">My Skills</Link>
      //       </>
      //     ),
      //   },
      // ],
      // },
      // {
      //   id: 26,
      //   value: "ai-interview",
      //   label: "AI Interview",
      //   path: "/employee/ai-interview",
      //   icon: <AssessmentIcon />,
      //   breadCrum: (
      //     <>
      //       Employee/
      //       <Link to="/employee/ai-interview">AI Interview</Link>
      //     </>
      //   ),
      // },
      {
        id: 27,
        value: "skill gap analysis",
        label: "Skill Gap Analysis",
        path: "/employee/skill-gap-analysis",
        icon: <SummarizeIcon />,
        breadCrum: (
          <>
            Employee/
            <Link to="/employee/skill-gap-analysis">Skill Gap Analysis</Link>
          </>
        ),
      },
      // {
      //   id: 28,
      //   value: "suggested-courses",
      //   label: "Suggested Courses",
      //   path: "/employee/suggested-courses",
      //   icon: <AutoStoriesIcon />,
      //   breadCrum: (
      //     <>
      //       Employee/
      //       <Link to="/employee/suggested-courses">Suggested Courses</Link>
      //     </>
      //   ),
      // },
    ],
  },
];