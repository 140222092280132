import { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  Card,
  Divider,
  IconButton,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/system";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { uploadCVs, getJobsAll } from "../../redux/actions/jobs";
import { getModelNameWithJobId } from "../../redux/actions/batches";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import { io } from "socket.io-client";
import Badge from "@mui/material/Badge";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import ProgressBarComponent from "../../components/ProgressBar";

let BASE_URL = localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL;
let orgName = localStorage.getItem('org_name');
let API_URL = '';
if (orgName) {
    API_URL = `${BASE_URL}/${orgName}`
} else {
    API_URL = `${BASE_URL}`
}
const socket = io(BASE_URL, {
  path: `/${orgName}/socket.io`,  // Adjust the path if necessary
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const CreateBatch = ({ uploadCVs, getJobsAll, allJobs, cvsUploaded,getModelNameWithJobId,modelNameOfJobId }) => {
  const navigate = useNavigate();
  const { jd_id } = useParams();

  const [anchorElAct, setAnchorElAct] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [activities, setActivities] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [totalSteps, setTotalSteps] = useState(0);
  const [countdown, setCountdown] = useState(10); // Added countdown state
  const [redirecting, setRedirecting] = useState(false); // Added redirecting state
  // const [selectedOption, setSelectedOption] = useState("Baali"); 
  const [selectedModel, setSelectedModel] = useState("");



  useEffect(() => {
    setJobId(jd_id);
  }, [jd_id]);

  useEffect(() => {
      getModelName(jd_id);
  }, [selectedModel]);



const getModelName = async (id) => {
     await getModelNameWithJobId(id);
    setSelectedModel(modelNameOfJobId?.jb_model_name || "Gemini");
  };

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });
    setUploadedFiles(uploaded);
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.from(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const onSubmitCVs = async () => {
    if (jobId) {
      let reqBody = new FormData();
      uploadedFiles.forEach((file) => {
        reqBody.append("files", file, file.name);
      });
      reqBody.append("model_name", modelNameOfJobId?.jb_model_name);
      await uploadCVs(reqBody, jobId);
      setTotalSteps(uploadedFiles.length);
      setUploadedFiles([]);
    }
  };

  const removeCV = (index) => () => {
    const files = [...uploadedFiles];
    files.splice(index, 1);
    setUploadedFiles(files);
    setFileLimit(false);
  };

  useEffect(() => {
    if (cvsUploaded.batch_id) {
      navigate(`/hr/batches/batch-detail/${cvsUploaded.batch_id}`);
    }
  }, [cvsUploaded]);

  // useEffect(() => {
  //   if (totalSteps > 0 && currentStep >= totalSteps) {
  //     setRedirecting(true);
  //     const interval = setInterval(() => {
  //       setCountdown((prevCountdown) => prevCountdown - 1);
  //     }, 1000);

  //     setTimeout(() => {
  //       clearInterval(interval);
  //       navigate(`/hr/batches/batch-detail/${cvsUploaded.batch_id}`);
  //     }, 10000);
  //   }
  // }, [currentStep, totalSteps, cvsUploaded.batch_id, navigate]);

  // useEffect(() => {
  //   if (currentStep < totalSteps) {
  //     setTimeout(() => {
  //       setCurrentStep((prevStep) => prevStep + 1);
  //     }, 6000);
  //   }
  // }, [currentStep, totalSteps]);

  // useEffect(() => {
  //   socket.on("to_client", (data) => {
  //     if (data.progress === 100) {
  //       setActivities((prevActivities) =>
  //         prevActivities.filter((act) => act.jd_id !== data.jd_id)
  //       );
  //     } else {
  //       setActivities((prevActivities) => {
  //         const activityExists = prevActivities.some(
  //           (act) => act.jd_id === data.jd_id
  //         );
  //         if (activityExists) {
  //           return prevActivities.map((act) =>
  //             act.jd_id === data.jd_id ? data : act
  //           );
  //         } else {
  //           return [...prevActivities, data];
  //         }
  //       });
  //     }
  //   });
  // }, []);

  const handleMenuAct = (event) => {
    setAnchorElAct(event.currentTarget);
  };

  const handleCloseAct = () => {
    setAnchorElAct(null);
  };

  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };

  return (
    <Card xs={12} sx={{ mt: 1, minHeight: "50%" }}>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={6}>
          <Typography variant={"h5"} sx={{ ml: 2, mt: 2 }}>
            Create Batch
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Analysing Module</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedModel}
              label="Analysing Module"
              // onChange={handleOptionChange}
              disabled = {true}
            >
              <MenuItem value={"chat_gpt"}>Chat GPT</MenuItem>
              <MenuItem value={"Baali"}>Baali</MenuItem>
              <MenuItem value={"gemini"}>Gemini</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={12} textAlign="center">
          <Button
            color="primary"
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            Upload CVs
            <VisuallyHiddenInput
              type="file"
              onChange={handleFileEvent}
              multiple
              disabled={fileLimit}
              accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />
          </Button>
          <br />
          <small>
            <InfoIcon /> <i>Only PDF & Docx files are supported!</i>
          </small>
          {/* {totalSteps > 0 && (
            <ProgressBarComponent currentStep={currentStep} totalSteps={totalSteps} />
          )} */}
          {uploadedFiles.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">SN</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uploadedFiles.map((file, index) => (
                    <TableRow key={file.name}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{file.name}</TableCell>
                      <TableCell>
                        <IconButton onClick={removeCV(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        {uploadedFiles.length > 0 && (
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={6}></Grid>
            <Grid item xs={6} textAlign="end">
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmitCVs}
                disabled={!jobId}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        )}
        {/* {redirecting && (
          <Grid item xs={12} textAlign="center" sx={{ mt: 4 }}>
            <CircularProgress />
            <Typography variant="h6" sx={{ mt: 2 }}>
              You are redirecting in {countdown} seconds...
            </Typography>
          </Grid>
        )} */}
      </Grid>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  allJobs: state.jobs.allJobs,
  cvsUploaded: state.jobs.cvsUploaded,
  modelNameOfJobId: state.batches.modelNameOfJobId,
});

const mapDispatchToProps = {
  uploadCVs,
  getJobsAll,
  getModelNameWithJobId,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBatch);
