import { useState, useEffect, useRef, useCallback } from "react";
import { Button, Stack, Typography, Modal, Box } from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllSelfEvalTest, submitMCQCod } from "../../redux/actions/dashboard";
import AlertDialog from "../../components/Dialog";
import CodeEditor from "../../components/Editor/CodeEditor";
import { getApplicant } from "../../redux/actions/applicant";
import { hideSidebar } from "../../redux/actions/sidebar";
import useFullscreen from "../../hooks/fullScreenWindow";
import { saveInterviewSession } from "../../redux/actions/candidate";

const modalPopUpStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '8px',
  textAlign: 'center',
};

const TestCoding = ({
  saveInterviewSession,
  auth: { user },
  dashboard: { test, testResultCode },
  getAllSelfEvalTest,
  submitMCQCod,
  skillData,
  hideSidebar
}) => {
  const navigate = useNavigate();
  const [answerSheetCoding, setAnswerSheetCoding] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [isPassing, setIsPassing] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  const { isFullScreen, enterFullScreen } = useFullscreen();
  const videoRef = useRef(null);
  const canvasRef = useRef(null); // Canvas to draw rectangles
  const [videoStream, setVideoStream] = useState(null);
  const [faceapi, setFaceApi] = useState(null);
  const [consentGiven, setConsentGiven] = useState(false); // Track consent
  const [consentModalOpen, setConsentModalOpen] = useState(true); // Consent modal state
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFaceDetected, setIsFaceDetected] = useState(true);

  // Load face-api models dynamically
  useEffect(() => {
    const loadFaceApi = async () => {
      const faceapi = await import('face-api.js');
      await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
      await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
      await faceapi.nets.faceRecognitionNet.loadFromUri('/models');
      setFaceApi(faceapi);
    };

    loadFaceApi();
  }, []);

  // Show error modal with dynamic message
  const showErrorModal = (message) => {
    setErrorMessage(message);
    setOpenErrorModal(true);
  };

  // Close error modal
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
    enterFullScreen();
  };

  // Camera and audio permission handling after consent
  const getUserMedia = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      setVideoStream(stream);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      showErrorModal("Camera and microphone access are required");
    }
  };

  // Handle consent acceptance
  const handleConsentAccept = () => {
    setConsentGiven(true);
    setConsentModalOpen(false);
    getUserMedia(); // Request camera and microphone access after consent
  };

  // Draw rectangles around detected faces
  const drawFaceDetections = (detections) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous drawings
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;

    detections.forEach((detection) => {
      const { x, y, width, height } = detection.box;
      ctx.strokeStyle = 'red';
      ctx.lineWidth = 2;
      ctx.strokeRect(x, y, width, height);
    });
  };

  // Facial detection and drawing bounding boxes
  useEffect(() => {
    let faceDetectionInterval;
    const detectFaces = async () => {
      if (videoRef.current && videoRef.current.readyState === 4 && faceapi) {
        const detections = await faceapi.detectAllFaces(
          videoRef.current,
          new faceapi.TinyFaceDetectorOptions({ inputSize: 160, scoreThreshold: 0.5 })
        );

        if (detections.length > 0) {
          drawFaceDetections(detections); // Draw rectangles around faces
        }

        if (detections.length > 1) {
          saveInterviewSession({ multiFaceDetected: true, apct_id: user.u_apct_id_fk })
          showErrorModal("More than one face detected. Please ensure only one person is visible.");
        }

        setIsFaceDetected(detections.length === 1);
      }
    };

    if (faceapi && consentGiven) {
      faceDetectionInterval = setInterval(detectFaces, 2000); // Check every 2 seconds
    }

    return () => clearInterval(faceDetectionInterval);
  }, [faceapi, consentGiven]);

  // Show warning if no face or multiple faces are detected
  useEffect(() => {
    if (consentGiven && !isFaceDetected) {
      showErrorModal("Face not detected. Please ensure only one face is visible.");
    }
  }, [isFaceDetected, consentGiven]);

  const handleAnswerSelection = (q_id, answer) => {
    setAnswerSheetCoding((prev) => {
      const updatedAnswers = prev.some((ans) => ans.q_id === q_id)
        ? prev.map((ans) => (ans.q_id === q_id ? { ...ans, answer } : ans))
        : [...prev, { q_id, answer }];
      return updatedAnswers;
    });
  };

  useEffect(() => {
    if (testResultCode) {
      navigate('/candidate/dashboard', { replace: true });
      window.location.reload();
    }
  }, [testResultCode]);

  const handleCodeChange = useCallback((code, q_id, ques) => {
    setAnswerSheetCoding((prev) => {
      const index = prev.findIndex((item) => item.q_id === q_id);
      const updatedSheet = index > -1
        ? prev.map((item, i) => (i === index ? { q_id, answer: code, ques } : item))
        : [...prev, { q_id, answer: code, ques }];
      return updatedSheet;
    });
  }, []);

  useEffect(() => {
    if (skillData?.length) {
      getAllSelfEvalTest({ skills: skillData }, "coding");
    }
  }, [skillData, getAllSelfEvalTest]);

  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prev) => Math.min(prev + 1, test.length - 1));
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleSubmitConfirmation = () => {
    const attemptedCount = answerSheetCoding.filter((ans) => ans.answer).length;
    setDialogTitle("Submit Test");
    setDialogContent(
      `You have attempted ${attemptedCount} out of ${test.length} questions. Are you sure you want to submit?`
    );
    setDialogOpen(true);
  };

  const handleFinalSubmit = () => {
    setOpenModal(false);
    navigate('/candidate/dashboard', { replace: true });
  };

  // Restriction handlers
  useEffect(() => {
    const handleCopy = (e) => {
      e.preventDefault();
      showErrorModal("Copying is not allowed");
    };

    const handleCut = (e) => {
      e.preventDefault();
      showErrorModal("Cutting is not allowed");
    };

    const handlePaste = (e) => {
      e.preventDefault();
      showErrorModal("Pasting is not allowed");
    };

    const codingPage = document.getElementById("codingPage");
    codingPage.addEventListener('copy', handleCopy);
    codingPage.addEventListener('cut', handleCut);
    codingPage.addEventListener('paste', handlePaste);

    return () => {
      codingPage.removeEventListener('copy', handleCopy);
      codingPage.removeEventListener('cut', handleCut);
      codingPage.removeEventListener('paste', handlePaste);
    };
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        showErrorModal("Switching Screen is not allowed");
        saveInterviewSession({ isFullScreen, apct_id: user.u_apct_id_fk })
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    console.log();
    if (confirmSubmit) {
      const allAnswers = test.map((q) => {
        const answer = answerSheetCoding.find((ans) => ans.q_id === q.q_id);
        // return { q_id: q.q_id, answer: answer ? answer.answer : null};
        return {
          q_id: q.q_id,
          answer: answer ? answer.answer : null,
          ques: answer ? answer.ques : q.q_name // Including the ques key
        };
      });
      submitMCQCod({ skills: skillData, answerSheetCoding: allAnswers });
      setDialogOpen(false);
      setConfirmSubmit(false);
    }
  }, [confirmSubmit, skillData, answerSheetCoding, submitMCQCod, test]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && (e.key === 'c' || e.key === 'x' || e.key === 'v')) {
        e.preventDefault();
        const actions = { c: 'Copying', x: 'Cutting', v: 'Pasting' };
        showErrorModal(`${actions[e.key]} is not allowed`);
      }
    };

    const codingPage = document.getElementById("codingPage");
    codingPage.addEventListener('keydown', handleKeyDown);

    return () => {
      codingPage.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
      showErrorModal("Action Not Allowed");
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  useEffect(() => {
    const handleMouseOut = (event) => {
      if (!event.relatedTarget) {
        saveInterviewSession({ isFullScreen, apct_id: user.u_apct_id_fk })
        showErrorModal("You cannot leave the window");
      }
    };

    window.addEventListener('mouseout', handleMouseOut);

    return () => {
      window.removeEventListener('mouseout', handleMouseOut);
    };
  }, []);

  return (
    <Stack direction="column" alignItems="center" id="codingPage">
      {!isFullScreen && (
        <Button id="enterFullScreenButton" variant="contained" onClick={enterFullScreen}>
          Enter Full Screen
        </Button>
      )}

      {/* Consent Modal */}
      <Modal
        open={consentModalOpen}
        onClose={() => { }}
        aria-labelledby="consent-modal-title"
        aria-describedby="consent-modal-description"
      >
        <Box sx={modalPopUpStyle}>
          <Typography variant="h6" component="h2" id="consent-modal-title">
            Consent Required
          </Typography>
          <Typography sx={{ mt: 2 }}>
            This test requires access to your camera and microphone for verification. Do you consent?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConsentAccept}
            sx={{ mt: 3 }}
          >
            Accept
          </Button>
        </Box>
      </Modal>

      {/* Error Modal */}
      <Modal
        open={openErrorModal}
        onClose={handleCloseErrorModal}
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
      >
        <Box sx={modalPopUpStyle}>
          <Typography variant="h6" component="h2" id="error-modal-title">
            Error
          </Typography>
          <Typography sx={{ mt: 2 }}>{errorMessage}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseErrorModal}
            sx={{ mt: 3 }}
          >
            OK
          </Button>
        </Box>
      </Modal>

      <AlertDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        title={dialogTitle}
        setConfirm={setConfirmSubmit}
        content={dialogContent}
      />

      <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
        <Button onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>
          Previous
        </Button>
        <Button onClick={handleNextQuestion} disabled={currentQuestionIndex === test?.length - 1}>
          Next
        </Button>
        <Button onClick={handleSubmitConfirmation}>Submit</Button>
        <Button onClick={() => navigate("/dashboard")}>Back</Button>
      </Stack>

      <Typography variant="h6" sx={{ textAlign: "center", mt: 3 }}>
        Test Coding
      </Typography>

      {test?.length > 0 && (
        <Stack direction="column" alignSelf="center" spacing={1} sx={{ pt: 2, width: "100%", userSelect: 'none' }}>
          <Typography variant="h6">
            {currentQuestionIndex + 1}. {test[currentQuestionIndex].q_name}
          </Typography>
          <CodeEditor
            key={test[currentQuestionIndex].q_id}
            onChange={(e) =>
              handleCodeChange(e, test[currentQuestionIndex].q_id, test[currentQuestionIndex].q_name)
            }
            style={{ width: "100%", userSelect: 'none' }}
          />
        </Stack>
      )}

      {/* Video and Canvas */}
      <video
        ref={videoRef}
        autoPlay
        muted
        style={{
          position: "fixed",
          bottom: "80px",
          right: "20px",
          width: "200px",
          height: "150px",
          border: "1px solid black",
          borderRadius: "8px",
          zIndex: 9999,
          display: consentGiven ? 'block' : 'none' // Only show video if consent is given
        }}
      />
      <canvas
        ref={canvasRef}
        style={{
          position: "fixed",
          bottom: "80px",
          right: "20px",
          width: "200px",
          height: "150px",
          zIndex: 10000, // Draw over the video
          pointerEvents: "none"
        }}
      />

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalPopUpStyle}>
          <Typography variant="h6" component="h2" id="modal-title">
            Your Score is
          </Typography>
          <Typography>Your code is under review, you can check on the dashboard.</Typography>
          <Button variant="contained" color={isPassing ? "success" : "error"} onClick={handleFinalSubmit}>
            OK
          </Button>
        </Box>
      </Modal>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  userData: state.auth.userData,
  dashboard: state.dashboard,
  skillData: state.applicant?.applicant?.skills,
  testResult: state.dashboard.testResult,
});

const mapDispatchToProps = {
  getAllSelfEvalTest,
  submitMCQCod,
  getApplicant,
  hideSidebar,
  saveInterviewSession
};

export default connect(mapStateToProps, mapDispatchToProps)(TestCoding);
