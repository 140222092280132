import React, { useState, useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';
import { getAIInterviewScore } from '../../services/dashboard';
import { useParams, Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Grid,
} from '@mui/material';
import { Person, Work, Code, Quiz, Star, Feedback } from '@mui/icons-material';
import html2pdf from 'html2pdf.js';

const safeRender = (value) => (typeof value === 'object' ? value.intqa_question : value || 'N/A');
const capitalizeFirstLetter = (string) => (string ? string.charAt(0).toUpperCase() + string.slice(1) : '');

const AiScoreReport = () => {
  const { apct_id } = useParams();
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(true);
  const reportRef = useRef(null);

  const fetchAIScoreData = async () => {
    let interviewData = await getAIInterviewScore(apct_id);
    if (interviewData.status === 200) {
      setResponse(interviewData.data);
      setLoading(false);
    }
  };

  const handleDownload = () => {
    const element = reportRef.current;
    const options = {
      margin: 0.5,
      filename: `ai-score.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    };
    html2pdf().from(element).set(options).save();
  };

  useEffect(() => {
    fetchAIScoreData();
  }, []);

  const { interview_report, mcq_report = [], coding_report = [] } = response;
  const { interviewerName, positionApplied, jobCode, feedback, questionList, answerList, overallfeedback } = interview_report || {};

  const config = {
    displayModeBar: false,
    responsive: true,
  };

  const calculateMCQSummary = () => {
    const totalQuestions = mcq_report.length;
    const totalCorrect = mcq_report.filter((mcq) => mcq.as_is_correct).length;
    const correctPercentage = totalQuestions ? ((totalCorrect / totalQuestions) * 100).toFixed(2) : '0.00';
    return { totalQuestions, totalCorrect, correctPercentage };
  };

  const mcqSummary = calculateMCQSummary();

  const calculateAIInterviewScore = () => {
    const aiScores = feedback ? feedback.map(item => item.score?.ans_acc || 0) : [];
    return aiScores.length ? (aiScores.reduce((sum, score) => sum + score, 0) / aiScores.length).toFixed(2) : 'N/A';
  };

  const aiInterviewScore = calculateAIInterviewScore();

  const calculateFinalScore = () => {
    const mcqScore = parseFloat(mcqSummary.totalCorrect);
    const codingScore = coding_report.length
      ? coding_report.reduce((sum, { as_coding_score }) => sum + as_coding_score, 0) / coding_report.length
      : 0;
    const scores = [mcqScore, codingScore, parseFloat(aiInterviewScore)].filter((score) => score > 0);
    return scores.length ? (scores.reduce((a, b) => a + b, 0) / scores.length).toFixed(2) : 'N/A';
  };

  const finalScore = calculateFinalScore();

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button onClick={handleDownload}>Download as PDF</Button>
        <h1 style={{ textAlign: 'center', flex: 1 }}>Score Report</h1>
        <Link to="/hr/candidates" style={{ marginRight: '20px' }}>
          Back
        </Link>
      </div>
      {loading ? (
        <CircularProgress />
      ) : (
        <div style={{ textAlign: 'center', padding: '10px' }} ref={reportRef}>
          <Card style={{ marginBottom: '20px', padding: '20px', textAlign: 'left' }}>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h6">
                    <Person style={{ verticalAlign: 'middle', color: '#3f51b5', marginRight: 5 }} />
                    Interviewee: <strong>{safeRender(interviewerName)}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h6">
                    <Work style={{ verticalAlign: 'middle', color: '#4caf50', marginRight: 5 }} />
                    Position: <strong>{safeRender(positionApplied)}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h6">
                    <Code style={{ verticalAlign: 'middle', color: '#ff5722', marginRight: 5 }} />
                    Job Code: <strong>{safeRender(jobCode)}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h6">
                    <Quiz style={{ verticalAlign: 'middle', color: '#2196f3', marginRight: 5 }} />
                    MCQ Score: <strong>{mcqSummary.totalCorrect} ({mcqSummary.correctPercentage}%)</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h6">
                    <Code style={{ verticalAlign: 'middle', color: '#9c27b0', marginRight: 5 }} />
                    Coding Score: <strong>{coding_report.length ? (coding_report.reduce((sum, { as_coding_score }) => sum + as_coding_score, 0) / coding_report.length).toFixed(2) : 'N/A'}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h6">
                    <Star style={{ verticalAlign: 'middle', color: '#ffeb3b', marginRight: 5 }} />
                    AI Interview Score: <strong>{aiInterviewScore}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    <Star style={{ verticalAlign: 'middle', color: '#ffeb3b', marginRight: 5 }} />
                    Final Score: <strong>{finalScore}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    <Feedback style={{ verticalAlign: 'middle', color: '#f44336', marginRight: 5 }} />
                    Overall Feedback: <strong>{safeRender(overallfeedback)}</strong>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* MCQ Report Section */}
          {mcq_report.length > 0 && (
            <>
              <h2>MCQ Report</h2>
              <TableContainer component={Paper} style={{ marginTop: '20px', width: '90%', margin: '0 auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Question No.</strong></TableCell>
                      <TableCell><strong>Answer Given</strong></TableCell>
                      <TableCell><strong>Correct</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mcq_report.map((mcq, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{safeRender(mcq.as_answer_given)}</TableCell>
                        <TableCell>{mcq.as_is_correct ? 'Yes' : 'No'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

          {/* Coding Report Section */}
          {coding_report.length > 0 && (
            <>
              <h2>Coding Report</h2>
              <TableContainer component={Paper} style={{ marginTop: '20px', width: '90%', margin: '0 auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Question No.</strong></TableCell>
                      <TableCell><strong>Score</strong></TableCell>
                      <TableCell><strong>Report</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {coding_report.map((coding, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{safeRender(coding.as_coding_score)}</TableCell>
                        <TableCell>{safeRender(coding.as_coding_report?.explanation)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

          {/* AI Interview Report Section */}
          <h2>AI Interview Report</h2>
          {questionList?.map((question, index) => {
            const feedbackItem = feedback?.[index] || {};
            const { score, emotion_score } = feedbackItem;
            const scores = score ? Object.values(score) : [];
            const labels = score ? Object.keys(score).map(capitalizeFirstLetter) : [];
            const emotionsScores = emotion_score ? Object.values(emotion_score) : [];
            const emotionsLabels = emotion_score ? Object.keys(emotion_score).map(capitalizeFirstLetter) : [];

            return (
              <Card key={index} style={{ marginBottom: '20px', width: '90%', margin: '0 auto' }}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Question {index + 1}
                  </Typography>
                  <Typography variant="body1">{safeRender(question)}</Typography>
                  <Typography variant="body2">
                    <strong>Answer:</strong> {safeRender(answerList?.[index])}
                  </Typography>

                  <Grid container spacing={2} style={{ marginTop: '20px' }}>
                    <Grid item xs={12} md={6}>
                      {scores.length > 0 && (
                        <>
                          <Plot
                            data={[{ type: 'pie', values: scores, labels, textinfo: 'label+percent', insidetextorientation: 'radial' }]}
                            layout={{ title: `Score Breakdown`, autosize: true, width: 300, height: 300 }}
                            config={config}
                          />
                          <TableContainer component={Paper} style={{ marginTop: '10px' }}>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Criteria</TableCell>
                                  <TableCell>Score (out of 10)</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {labels.map((label, i) => (
                                  <TableRow key={i}>
                                    <TableCell>{label}</TableCell>
                                    <TableCell>{scores[i]}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {emotionsScores.length > 0 && (
                        <>
                          <Plot
                            data={[{ type: 'bar', x: emotionsLabels, y: emotionsScores, text: emotionsScores.map(String), textposition: 'auto' }]}
                            layout={{ title: `Emotion Breakdown`, xaxis: { title: 'Emotions' }, yaxis: { title: 'Scores' }, autosize: true, width: 300, height: 300 }}
                            config={config}
                          />
                          <TableContainer component={Paper} style={{ marginTop: '10px' }}>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Emotion</TableCell>
                                  <TableCell>Score (out of 10)</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {emotionsLabels.map((label, i) => (
                                  <TableRow key={i}>
                                    <TableCell>{label}</TableCell>
                                    <TableCell>{emotionsScores[i]}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>
                      )}
                    </Grid>
                  </Grid>

                  <Typography variant="body2" style={{ marginTop: '20px' }}>
                    <strong>Feedback:</strong> {safeRender(feedbackItem?.feedback)}
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AiScoreReport;
