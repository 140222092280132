import { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  Card,
  Divider,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
  TableHead,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getJob, updateJob, clearCreatedJD} from "../../redux/actions/jobs";
import moment from "moment";
import CandidatesJd from "./CnadidatesJd";
import BatchesJD from "./BatchesJD";
import { _downloadJD } from "../../services/jobs.service";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from "react-toastify";
import { formatDate } from "../../utils/dateUtils";

export const JobDetails = ({ getJob, job, updateJob, clearCreatedJD }) => {
  const [JobDetail, setJobDetail] = useState(null);
  const [mustHaveSkills, setMustHaveSkills] = useState([]);
  const [niceToHaveSkills, setNiceToHaveSkills] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [title, setTitle] = useState();
  const [isEditSkill, setIsEditSkill] = useState(false);
  const [newMustHaveSkill, setNewMustHaveSkill] = useState(null);
  const [newNiceToHaveSkill, setNewNiceToHaveSkill] = useState(null);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [skillToDelete, setSkillToDelete] = useState(null);
  const [isDeletingMustHave, setIsDeletingMustHave] = useState(false);
  const [isDeletingNiceToHave, setIsDeletingNiceToHave] = useState(false);

  let { jd_id } = useParams();

  useEffect(() => {
    clearCreatedJD();
  }, [])

  useEffect(() => {
    if (jd_id) {
      getJob(jd_id);
    }
  }, [jd_id]);

  useEffect(() => {
    if (job) {
      setJobDetail(job);
      if (job.jb_must_have) setMustHaveSkills(JSON.parse(job.jb_must_have));
      if (job.jb_nice_have) setNiceToHaveSkills(JSON.parse(job.jb_nice_have));
      if (job.jb_name) setTitle(job.jb_name);
    }
  }, [job]);

  const handleChangeSkill = (e, isAdditional, id) => {
    let value = e.target.value;
    if (id === "new") {
      if (isAdditional) {
        setNewNiceToHaveSkill({ ...newNiceToHaveSkill, name: value });
      } else {
        setNewMustHaveSkill({ ...newMustHaveSkill, name: value });
      }
    } else {
      if (isAdditional) {
        let skills = niceToHaveSkills.map((skill) => {
          if (skill.id === id) {
            skill.name = value;
          }
          return skill;
        });
        setNiceToHaveSkills(skills);
      } else {
        let skills = mustHaveSkills.map((skill) => {
          if (skill.id === id) {
            skill.name = value;
          }
          return skill;
        });
        setMustHaveSkills(skills);
      }
    }
  };

  const handleAddSkill = (isAdditional) => {
    setIsEditSkill(true);
    if (isAdditional) {
      setNewNiceToHaveSkill({ name: "", weightage: 1 });
    } else {
      setNewMustHaveSkill({ name: "", weightage: 5 });
    }
  };

  const handleCancelNewSkill = (isAdditional) => {
    if (isAdditional) {
      setNewNiceToHaveSkill(null);
    } else {
      setNewMustHaveSkill(null);
    }
  };

  const handleOpenDeleteDialog = (skill, isMustHave) => {
    setSkillToDelete(skill);
    setIsDeletingMustHave(isMustHave);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSkillToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleDeleteSkill = async () => {
    if (isDeletingMustHave) {
      setMustHaveSkills(
        mustHaveSkills.filter((skill) => skill.id !== skillToDelete.id)
      );
      await updateJob(jd_id, {
        jb_must_have: JSON.stringify(
          mustHaveSkills.filter((skill) => skill.id !== skillToDelete.id)
        ),
        jb_nice_have: JSON.stringify(niceToHaveSkills),
      });
    } else {
      setNiceToHaveSkills(
        niceToHaveSkills.filter((skill) => skill.id !== skillToDelete.id)
      );
      await updateJob(jd_id, {
        jb_must_have: JSON.stringify(mustHaveSkills),
        jb_nice_have: JSON.stringify(
          niceToHaveSkills.filter((skill) => skill.id !== skillToDelete.id)
        ),
      });
    }
    handleCloseDeleteDialog();
  };

  // const handleChangeSkill = (e, isAdditional, id) => {
  //   let value = e.target.value;
  //   if (isAdditional) {
  //     let skills = niceToHaveSkills.map((skill) => {
  //       if (skill.id === id) {
  //         skill.name = value;
  //       }
  //       return skill;
  //     });
  //     setNiceToHaveSkills(skills);
  //   } else {
  //     let skills = mustHaveSkills.map((skill) => {
  //       if (skill.id === id) {
  //         skill.name = value;
  //       }
  //       return skill;
  //     });
  //     setMustHaveSkills(skills);
  //   }
  // };

  const handleChangeWeightage = (e, id) => {
    let value = e.target.value;
    if( value < 1 && value != "") {
      value = 1;
    }
    
    if (value > 4) {
      value = 4;
    }

    if (id === "new") {
      setNewNiceToHaveSkill({ ...newNiceToHaveSkill, weightage: value });
    } else {
      let skills = niceToHaveSkills.map((skill) => {
        if (skill.id === id) {
          skill.weightage = value;
        }
        return skill;
      });
      setNiceToHaveSkills(skills);
    }
  };

  const onClickUpdate = async () => {
    let data = {
      title: title,
      jb_must_have: JSON.stringify(mustHaveSkills),
      jb_nice_have: JSON.stringify(niceToHaveSkills),
    };
    await updateJob(jd_id, data);

    setIsEdit(false);
  };
  const onClickUpdateSkills = async (isMustHave) => {
    // give error if skill name is empty

    if (isMustHave) {
      if (!newMustHaveSkill.name) {
        toast.error("Skill name is required");
        return;
      }
      const _mustskill = {
        id: uuidv4(),
        name: newMustHaveSkill.name,
        weightage: 5,
      };

      // Append new must have skill to the existing list
      const updatedMustHaveSkills = [_mustskill, ...mustHaveSkills];

      // Update state with new must have skills
      setMustHaveSkills(updatedMustHaveSkills);

      let data = {
        jb_must_have: JSON.stringify(updatedMustHaveSkills),
        jb_nice_have: JSON.stringify(niceToHaveSkills),
      };

      await updateJob(jd_id, data);
    } else {
      if (!newNiceToHaveSkill.name) {
        toast.error("Skill name is required");
        return;
      }
      const _niceskill = {
        id: uuidv4(),
        name: newNiceToHaveSkill.name,
        weightage: newNiceToHaveSkill.weightage,
      };

      // Append new nice to have skill to the existing list
      const updatedNiceToHaveSkills = [_niceskill, ...niceToHaveSkills];

      // Update state with new nice to have skills
      setNiceToHaveSkills(updatedNiceToHaveSkills);

      let data = {
        jb_must_have: JSON.stringify(mustHaveSkills),
        jb_nice_have: JSON.stringify(updatedNiceToHaveSkills),
      };
      // Uncomment the line below to update the job with new skills
      await updateJob(jd_id, data);
    }

    setIsEditSkill(false); // Close the edit mode for skills
  };

  return (
    <Box sx={{ mt: 1 }}>
      <Card xs={12} sx={{ mt: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5" sx={{ ml: 2, mt: 2 }}>
            Job Description Details
          </Typography>
          {isEdit ? (
            <div>
              <Button
                variant="text"
                size={"small"}
                onClick={() => setIsEdit(false)}
                sx={{ mt: 1, color: "red" }}
              >
                <CloseIcon /> Cancel
              </Button>
              <Button
                variant="text"
                size={"small"}
                onClick={onClickUpdate}
                sx={{ mr: 2, mt: 1, color: "green" }}
              >
                <SaveIcon /> Save
              </Button>
            </div>
          ) : (
            <div>
              {/* <Button
                size="small"
                color="primary"
                component="label"
                variant="text"
                onClick={() => _viewJD(jd_id && jd_id)}
                disabled={!jd_id}
                sx={{ mt: 1 }}
              >
                <VisibilityIcon /> View
              </Button> */}
              <Button
                size="small"
                color="primary"
                component="label"
                variant="text"
                onClick={() => _downloadJD(JobDetail && JobDetail)}
                disabled={!JobDetail}
                sx={{ mt: 1 }}
              >
                <DownloadIcon /> Download
              </Button>
              <Button
                variant="text"
                color="primary"
                size={"small"}
                onClick={() => setIsEdit(true)}
                sx={{ mr: 2, mt: 1 }}
              >
                <EditIcon /> Edit
              </Button>
            </div>
          )}
        </Box>
        <Divider />
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={4} sm={2}>
            <Typography variant="subtitle2" fontSize={14}>
              Job Title :
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4}>
            {isEdit ? (
              <TextField
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                disabled={!isEdit}
              />
            ) : (
              <Typography variant="subtitle1" fontSize={14} noWrap>
                {" "}
                {title}
              </Typography>
            )}
          </Grid>

          <Grid item xs={4} sm={2}>
            <Typography variant="subtitle2" fontSize={14}>
              Domain :
            </Typography>
          </Grid>

          <Grid item xs={8} sm={4}>
            <Typography variant="subtitle1" fontSize={14}>
              {JobDetail ? JobDetail.domain : ""}
            </Typography>
          </Grid>

          <Grid item xs={4} sm={2}>
            <Typography variant="subtitle2" fontSize={14}>
              Min Experience :
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4}>
            <Typography variant="subtitle1" fontSize={14}>
              {JobDetail ? JobDetail.jb_min_exp : 0}
            </Typography>
          </Grid>

          <Grid item xs={4} sm={2}>
            <Typography variant="subtitle2" fontSize={14}>
              Technology :
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4}>
            <Typography variant="subtitle1" fontSize={14}>
              {JobDetail ? JobDetail.technology : ""}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={2}>
            <Typography variant="subtitle2" fontSize={14}>
              Max Experience :
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4}>
            <Typography variant="subtitle1" fontSize={14}>
              {JobDetail ? JobDetail.jb_max_exp : 0}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={2}>
            <Typography variant="subtitle2" fontSize={14}>
              Created At :
            </Typography>
          </Grid>
          <Grid item xs={8} sm={4}>
            <Typography variant="subtitle1" fontSize={14}>
              {JobDetail && JobDetail.jb_created_at
                ? formatDate(JSON.parse(localStorage.getItem("user")), JobDetail?.jb_created_at)
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6}></Grid>
          <Grid item xs={6} sm={6}></Grid>
        </Grid>
      </Card>
      <Card xs={12} sx={{ mt: 1 }}>
        <Typography variant={"h5"} sx={{ mt: 2, ml: 2 }}>
          Skills
        </Typography>
        <Divider />
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={6} sm={6}>
            <Typography variant="subtitle2" fontSize={14}>
              Must Have Skills (weightage : 5)
            </Typography>

            {mustHaveSkills && mustHaveSkills.length > 0 ? (
              <TableContainer component={Paper} sx={{ mt: 3, maxHeight: 300 }}>
                <Table
                  aria-label="Must Have Skills (weightage : 5)"
                  size="small"
                  align={"right"}
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <TableCell width={200} align="left">
                        Skill Name
                      </TableCell>
                      <TableCell width={200} align="left">
                        Weightage
                      </TableCell>
                      {
                        isEdit && (
                           <TableCell width={100} align="left"> 

                        <Button
                          variant="text"
                          size={"small"}
                          sx={{ color: "white" }}
                          onClick={() => handleAddSkill(false)}
                        >
                          +Add
                        </Button>
                      </TableCell>
                        )

                      }
                     
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isEditSkill && newMustHaveSkill && (
                      <TableRow>
                        <TableCell>
                          <TextField
                            name="name"
                            value={newMustHaveSkill.name}
                            onChange={(e) => handleChangeSkill(e, false, "new")}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell align="left">5</TableCell>
                      </TableRow>
                    )}
                    {mustHaveSkills.map((row, index) => (
                      <TableRow key={row.id}>
                        {isEdit ? (
                          <TextField
                            name="name"
                            value={row.name}
                            onChange={(e) =>
                              handleChangeSkill(e, false, row.id)
                            }
                            fullWidth
                            inputProps={{
                              style: { fontFamily: "inherit" },
                            }}
                          />
                        ) : (
                          <TableCell TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                        )}
                        <TableCell align="left">5</TableCell>
                        {
                          isEdit && (
                        <TableCell align="right">
        <IconButton onClick={() => handleOpenDeleteDialog(row, true)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
                          )
                        }
 
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
            {isEditSkill && newMustHaveSkill && (
              <TableRow>
                <Button onClick={() => onClickUpdateSkills(true)}>Save</Button>
                <Button onClick={() => handleCancelNewSkill(false)}>
                  Cancel
                </Button>
              </TableRow>
            )}
          </Grid>

          <Grid item xs={6} sm={6}>
            <Typography variant="subtitle2" fontSize={14}>
              Nice to Have Skills (Weightage : 1-4)
            </Typography>
            {niceToHaveSkills && niceToHaveSkills.length > 0 ? (
              <TableContainer component={Paper} sx={{ mt: 3, maxHeight: 300 }}>
                <Table
                  aria-label="Nice to Have Skills (Weightage : 1-4)"
                  size="small"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow style={{ height: 33 }}>
                      <TableCell width={200} align="left">
                        Skill Name
                      </TableCell>
                      <TableCell  width={200} align="left">Weightage</TableCell>
                      {
                        isEdit && (
<TableCell width={100} align="left">
                        <Button
                          variant="text"
                          size={"small"}
                          sx={{ color: "white" }}
                          onClick={() => handleAddSkill(true)}
                        >
                          +Add
                        </Button>
                      </TableCell>
                        )
                      }
                        
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isEditSkill && newNiceToHaveSkill && (
                      <TableRow>
                        <TableCell>
                          <TextField
                            name="name"
                            value={newNiceToHaveSkill.name}
                            onChange={(e) => handleChangeSkill(e, true, "new")}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            name="weightage"
                            value={newNiceToHaveSkill.weightage}
                            onChange={(e) => handleChangeWeightage(e, "new")}
                            fullWidth
                            inputProps={{
                              autoComplete: "off",
                              step: "1",
                              min: 1,
                              max: 4,
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                    {niceToHaveSkills.map((row, index) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        {isEdit ? (
                          <>
                            <TableCell component="th" scope="row">
                              <TextField
                                name="name"
                                value={row.name}
                                onChange={(e) =>
                                  handleChangeSkill(e, true, row.id)
                                }
                                fullWidth
                                inputProps={{
                                  style: { fontFamily: "inherit" },
                                }}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <TextField
                                type="number"
                                name="weightage"
                                value={row.weightage}
                                onChange={(e) =>
                                  handleChangeWeightage(e, row.id)
                                }
                                fullWidth
                                inputProps={{
                                  autoComplete: "off",
                                  step: "1",
                                  min: 1,
                                  max: 4,
                                }}
                              />
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="left">{row.weightage}</TableCell>
                          </>
                        )}
                        {
                          isEdit && (
                             <TableCell align="right">
        <IconButton onClick={() => handleOpenDeleteDialog(row, false)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
                          )
                        }
                        
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
            {isEditSkill && newNiceToHaveSkill && (
              <TableRow>
                <Button onClick={() => onClickUpdateSkills(false)}>Save</Button>
                <Button onClick={() => handleCancelNewSkill(true)}>
                  Cancel
                </Button>
              </TableRow>
            )}
          </Grid>
        </Grid>
      </Card>
      {JobDetail && JobDetail.jb_id ? (
        <>
          <BatchesJD jDID={JobDetail.jb_id} />

          <CandidatesJd jDID={JobDetail.jb_id} />
        </>
      ) : null}

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this skill?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteSkill} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  job: state.jobs.job,
});

const mapDispatchToProps = { getJob, updateJob, clearCreatedJD };

export default connect(mapStateToProps, mapDispatchToProps)(JobDetails);
